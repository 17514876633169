.form-card {
  border-radius: 24px;
  border: 1px;
  gap: 32px;
  background: linear-gradient(0deg, #fcfcfc, #fcfcfc);
  border: 1px solid #e4e4e4;
  box-shadow: 8px 8px 48px 0px #00000029;
  padding: 24px 16px 24px 16px;
  width: 490px;
}

.form-heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.form-input {
  width: 100%;
  height: 40px;
  font-size: 18px;
  padding-left: 8px;
  border: none;
  outline: none;
}

.input-group {
  border-radius: 8px;
  flex-wrap: nowrap;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
}

.input-icon {
  font-size: 1.5rem;
  margin: 0.4rem;
}

.form-btn {
  width: 100%;
  height: 40px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 10px;
}

.login-btn {
  margin-top: 1.5rem;
  background: #77a3c5;
  color: white;
  border: none;
  margin-bottom: 0.5rem;
}

.forget-btn {
  color: #77a3c5;
  background: white;
  border: 1px solid #77a3c5;
  margin-bottom: 1rem;
}

.form-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}
.form-text a {
  color: #77a3c5;
  text-decoration: none;
}

.admin-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(
    128.28deg,
    #346633 0%,
    rgba(63, 127, 154, 0.75) 73.7%
  );
}

.bx-low-vision {
  cursor: pointer;
}
